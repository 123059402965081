<template>
  <div class="orderList">
    <div style="position: sticky; z-index: 20; top: 0;">
      <search-bar-new :placeholder-text="queryType == 1 ? $t('请输入客户姓名/订单编号/客户手机号') : $t('请输入客户姓名/订单编号/顾问/客户手机号')" :str="searchStr"
        :showScreen="true" :showCategory="queryType !== 1 && userInfo.roleLogos.includes('1014003')"
        :actions="[{ text: $t('重新分配'), key: 'distribute', premission: 'BTN-ORDER-REDISTRIBUTE' }]"
        :active="Object.values(filterParams).length > 0" @input="onInput" @search="onRefresh"
        @screening="filterPanelVisible = true" @operation="operation">
      </search-bar-new>
      <FilterPanel ref="filterPanel" v-model="filterPanelVisible" :options="filterOptions" @submit="onFilter" />
      <VanTabs :queryType="queryType" :tabList="tabList" @change="changeTab"></VanTabs>
    </div>
    <div  class="totalCount">{{$t('共')}}{{ totalCount }}{{$t('条数据')}}</div>
<!--    <OptionsBox v-if="queryType == 4" :optionsList="optionsList" :subType="subType" :showCount="false"-->
<!--      @change="(value) => { subType = value, this.cleanDistribution(), this.$refs.list.onRefresh() }">-->
<!--    </OptionsBox>-->
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <OrderItem :dataSource="item" :isDistributionState="isDistributionState"
          :queryType="queryType" @change="onCheckChanged" @goDetail="goDetail(item)" />
      </template>
    </List>
    <div v-if="isDistributionState" class="action-bar">
      <div class="left">
        <span @click="cleanDistribution">{{$t('取消')}}</span>
        <span @click="onAllChecked">{{$t('全选')}}</span>
      </div>
      <div class="right">
        <van-button square color="#EED484" @click="onDistribution">
          {{$t('分配')}}{{ distributionIds.length ? `(${distributionIds.length})` : '' }}
        </van-button>
      </div>
    </div>
    <!-- 员工列表面板 -->
    <BusinessFilters ref="filter" v-model="showDistribution" :options="distributionOptions"
      :defaultActiveKey="['saIdList']" :buttonNameReset="$t('取消')" @reset="showDistribution = false" @submit="distributionFilter">
    </BusinessFilters>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SearchBarNew from '@/components/search-bar-new'
import formatParams from '@/utils/formatParams'
import VanTabs from '@/components/VanTabs'
import List from '@/components/baseList/list.vue'
import FilterPanel from '@/components/filter-panel'
import loading from '@/utils/loading'
import orderServices from '@/services/orderServices'
import { v4 as uuidv4 } from 'uuid'
import baseDataServices from '@/services/baseDataServices'
import OrderItem from './component/orderItem'
import { parseQuery } from '@/utils'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import { ROLES } from '@/constants/constants'
import OptionsBox from '@/components/OptionsBox'
import salesAssistantService from '@/services/salesAssistant'
import dayjs from 'dayjs'
export default {
  name: 'orderList',
  components: {
    List,
    FilterPanel,
    SearchBarNew,
    VanTabs,
    OrderItem,
    BusinessFilters,
    OptionsBox
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'orderList')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.filterParams = {}
      this.$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'orderList')
    }
    next()
  },
  data() {
    return {
      searchStr: '',
      isDistributionState: false,
      filterPanelVisible: false,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      distributionIds: [],
      queryType: 0,
      filterOptions: [],
      filterParams: {},
      showDistribution: false,
      totalCount: 0,
      subType: 1,
      tabList: [
        { text: this.$t('全部'), value: 0 },
        { text: this.$t('定金待支付'), value: 1, total: 0, key: 'toPayCount' },
        { text: this.$t('定金已支付'), value: 2, total: 0, key: 'createCount' },
        { text: this.$t('已锁单'), value: 3, total: 0, key: 'lockedCount' },
        { text: this.$t('已推合同'), value: 5, total: 0, key: 'pushedCount' },
        { text: this.$t('已签合同'), value: 6, total: 0, key: 'toDeliveryCount' },
        { text: this.$t('已交车'), value: 9, total: 0, key: 'deliveryCount' },
      ],
      roleCode: ''
    }
  },
  computed: {
    ...mapGetters({ getMenu: 'app/getMenu', userInfo: 'userInfo', dictHash: 'dictHash' }),
    distributionOptions() {
      return [{
        name: '',
        type: 'B_PEOPLE_SELECTION',
        field: 'saIdList',
        isSelection: true,
        valueType: 'object',
        shopIds: '',
        height: '80vh',
        roleCodes: ROLES.SALES
      }]
    },
    optionsList() {
      return [
        { text: this.$t('20小时（含）以内'), value: 1 },
        { text: this.$t('20-24小时'), value: 2 },
        { text: this.$t('24小时后'), value: 3 },
      ]
    },
    isDealer() {
      return this.userInfo.type === 1 
    },
  },
  watch: {
    dictHash: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.createClueFilterOptions()
          this.init()
        }
      },
    },
    filterPanelVisible:{
      handler(newVal){
        if (newVal){
          setTimeout(() => {
            this.init()
          }, 500)
        }
      }
    }
  },
  created(){
    this.init()
  },
  mounted() {
    // this.getLictCount()
    this.onRefresh() // 不能删除
  },
  activated() {
    this.init()
    this.onRefresh()
  },
  methods: {
    // 销售助手-跳转过来
    init() {
      const { 
        followUserIds,
        deliveryTimeStart,
        deliveryTimeEnd,
        orderStatuses,
        roleCode,
        contractTimeStart,
        contractTimeEnd,
        lockTimeStart,
        lockTimeEnd,
        parentCode='',
        childCode='',
        dealerProvinceCode='',
        dealerCityCode='',
        dealerIds='' } = this.$route.query
      this.roleCode = roleCode
      // 跟进人
      if (followUserIds){
        const userIds = Array.isArray(followUserIds) ? followUserIds : [followUserIds]
        if (userIds){
          this.$set(this.filterParams,'salesUserIds',userIds)
          setTimeout(()=>{
            this.$refs.filterPanel.formData.salesUserIds = []
            userIds.forEach(id=>{
              this.$refs.filterPanel.formData.salesUserIds.push({
                id: id,value: id
              }) 
            })
          },2000)
        }
      }
      // 交车时间
      if (deliveryTimeStart&&deliveryTimeEnd){
        this.$set(this.filterParams,'deliveryTimeStart',deliveryTimeStart)
        this.$set(this.filterParams,'deliveryTimeEnd',deliveryTimeEnd)
        setTimeout(()=>{
          this.$refs.filterPanel.formData.deliveryTimeStart = dayjs(deliveryTimeStart).toDate()
          this.$refs.filterPanel.formData.deliveryTimeEnd = dayjs(deliveryTimeEnd).toDate()
        },1000)
      }
      // 合同签署时间
      if (contractTimeStart && contractTimeEnd) {
        this.$set(this.filterParams,'contractTimeStart', contractTimeStart)
        this.$set(this.filterParams,'contractTimeEnd', contractTimeEnd)
        this.$set(this.filterParams,'contractStatus', 1)
        setTimeout(()=>{
          this.$refs.filterPanel.formData.contractTimeStart = dayjs(contractTimeStart).toDate()
          this.$refs.filterPanel.formData.contractTimeEnd = dayjs(contractTimeEnd).toDate()
          this.$refs.filterPanel.formData.contractStatus = {
            id: 1,
            value: 1,
          }
        },1000)
      }
      // 锁单时间
      if (lockTimeStart && lockTimeEnd) {
        this.$set(this.filterParams,'lockTimeStart', lockTimeStart)
        this.$set(this.filterParams,'lockTimeEnd', lockTimeEnd)
        setTimeout(()=>{
          this.$refs.filterPanel.formData.lockTimeStart = dayjs(lockTimeStart).toDate()
          this.$refs.filterPanel.formData.lockTimeEnd = dayjs(lockTimeEnd).toDate()
        },1000)
      }
      // 订单状态
      if (orderStatuses){
        this.$set(this.filterParams,'orderStatuses',[orderStatuses])
        setTimeout(()=>{
          this.$refs.filterPanel.formData.orderStatuses = [{ id: '144',value:orderStatuses }]
        },1000)
      }
      if (parentCode) {
        this.$set(this.filterParams,'parentCode', parentCode)
      }
      if (childCode) {
        this.$set(this.filterParams,'childCode',[childCode])
      }
      if (dealerProvinceCode) {
        this.$set(this.filterParams,'dealerProvinceCode',dealerProvinceCode)
      }
      if (dealerCityCode) {
        this.$set(this.filterParams,'dealerCityCode',dealerCityCode)
      }
      if (dealerIds) {
        this.$set(this.filterParams,'dealerIds',[dealerIds])
      }
    },
    async createClueFilterOptions() {
      let ret = []
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        let list = dictHash[dictType] || []
        if (dictType === 3000) {
          list = list.filter(({ code }) => !['BE_DELIVERY_BALANCE_PAID', 'CONTRACTED', 'IN_REFUNDING','ORDER_CLOSED','REFUNDED'].includes(code))
        }
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))

      }
      if (!this.isDealer) {
        const { parentCode='',
          childCode='',
          dealerProvinceCode='',
          dealerCityCode='',
          dealerIds=''
        } = this.$route.query
        const parentList = await salesAssistantService.getRegionTreeApi()
        // 大区
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('大区'),
          field: 'parentCode',
          defaultValue: parentCode,
          options: [],
          options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
        })
        // 城市区域
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市区域'),
          multiple: true,
          field: 'childCode',
          defaultValue: childCode,
          options: [],
        })
        const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
        // 省份
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('省份'),
          field: 'dealerProvinceCode',
          defaultValue: dealerProvinceCode,
          options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
        })
        // 城市
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市'),
          field: 'dealerCityCode',
          defaultValue: dealerCityCode,
          options: [],
        })
        // 销售门店
        const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '' })
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('销售门店'),
          multiple: true,
          field: 'dealerIds',
          defaultValue: dealerIds,
          options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
        })
      }
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('订单状态'),
        field: 'orderStatuses',
        multiple: true,
        options: getOptionsByDictType(3000),
      })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'dataRange',
      //   dateType: 'datetime',
      //   label: this.$t('下单时间'),
      //   start: {
      //     field: 'start',
      //     value: this.filterParams.start
      //   },
      //   end: {
      //     field: 'end',
      //     value: this.filterParams.end
      //   },
      // })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('锁单时间'),
        start: {
          field: 'lockTimeStart',
          // value: this.filterParams.lockTimeStart
        },
        end: {
          field: 'lockTimeEnd',
          // value: this.filterParams.lockTimeEnd
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('交车时间'),
        start: {
          field: 'deliveryTimeStart',
          // value: this.filterParams.deliveryTimeStart
        },
        end: {
          field: 'deliveryTimeEnd',
          // value: this.filterParams.deliveryTimeEnd
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('合同签署状态'),
        field: 'contractStatus',
        options: [
          { id: 1, value: 1, label: this.$t('已签署') },
          { id: 2, value: 2, label: this.$t('未签署') }
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('合同签署时间'),
        start: {
          field: 'contractTimeStart',
          value: this.filterParams.contractTimeStart
        },
        end: {
          field: 'contractTimeEnd',
          value: this.filterParams.contractTimeEnd
        },
      })
      const seriesList = await baseDataServices.getAllSeriesModels()
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('订单车型'),
        field: 'seriesCodes',
        multiple: true,
        options: seriesList.map(({ code, name, models }) => ({ id: code, label: name, value: code, modelList: models })),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '订单类型',
        field: 'orderType',
        options: [
          { id: 3, value: 3, label: 'C端订单' },
          { id: 1, value: 1, label: '大客户订单' },
          { id: 2, value: 2, label: '经销商试驾车' },
          // { id: 4, value: 4, label: 'SCRM代客零售' },
          { id: 5, value: 5, label: '大客户试驾车' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '订单类型细分',
        field: 'coVehicleSource',
        options: [
          { id: 2, value: 2, label: '特殊车（非一车一价）' },
          { id: 1, value: 1, label: '普通商品车' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'modelCodes',
        multiple: true,
        options: [],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('付款方式'),
        field: 'installmentsFlag',
        multiple: true,
        options: [
          { id: uuidv4(), value: 0, label: this.$t('全款') },
          { id: uuidv4(), value: 1, label: this.$t('分期') }
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('金融状态'),
        field: 'financeStatus',
        multiple: true,
        // options: [
        //   { id: uuidv4(), value: 1, label: this.$t('审核中') },
        //   { id: uuidv4(), value: 2, label: this.$t('已取消') }
        // ],
        options: getOptionsByDictType(4005)
      })
      if (this.$store.getters.userInfo.type === 1) {
        const dccStaffList = await baseDataServices.getStaffList({ roleLogo: '1014002', dealerId: this.userInfo.dealers[0]?.id })
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('销售顾问'),
          field: 'salesUserIds',
          multiple: true,
          options: dccStaffList.map((item) => ({ ...item, label: item.name, value: item.id })),
        })
      }
      this.filterOptions = ret
    },
    // 输入框键入
    onInput(value) {
      this.searchStr = value
    },
    // 刷新
    onRefresh() {
      // 清除分配信息
      this.cleanDistribution()
      this.getLictCount()
      this.$refs.list.onRefresh()
    },
    changeTab(val) {
      this.queryType = val
      this.subType = 1
      this.cleanDistribution()
      this.onRefresh()
    },
    async getLictCount() {
      const {
        queryType,
        searchStr
      } = this
      const params = {
        ...this.filterParams,
        queryType,
        searchStr,
        // orderCreatedTime: {
        //   start: this.filterParams.start,
        //   end: this.filterParams.end,
        // },
        lockTimeReq: {
          start: this.filterParams.lockTimeStart,
          end: this.filterParams.lockTimeEnd,
        },
        deliveryTime: {
          start: this.filterParams.deliveryTimeStart,
          end: this.filterParams.deliveryTimeEnd
        },
        contractTimeReq: {
          start: this.filterParams.contractTimeStart,
          end: this.filterParams.contractTimeEnd
        },
      }
      if (this.roleCode) {
        params.roleCode = this.roleCode
      }
      let tabCount = await orderServices.getOrderListCount(params)
      tabCount = Object.assign({
        contractToConfirmCount: 0,
        toAssignCount: 0,
        toPaidCount: 0,
        toSignCount: 0,
        toLockCount: 0,
        toDeliveryCount: 0
      }, tabCount)
      this.tabList.forEach(item => {
        if (item.key) item.total = tabCount[item.key] || 0
      })
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)

      // 已下定
      // if (this.filterParams.orderStatuses && this.filterParams.orderStatuses.length > 0 && this.filterParams.orderStatuses.includes('DEPOSITE_BE_PAID')) {
      //   this.filterParams.orderStatuses = this.filterParams.orderStatuses.concat(['BE_LOCK'])
      // }
      // 已签合同
      if (this.filterParams.orderStatuses && this.filterParams.orderStatuses.length > 0 && this.filterParams.orderStatuses.includes('BE_DELIVERY_BALANCE_BE_PAY')) {
        this.filterParams.orderStatuses = this.filterParams.orderStatuses.concat(['BE_DELIVERY_BALANCE_PAID', 'CONTRACTED'])
      }
      // 已取消
      if (this.filterParams.orderStatuses && this.filterParams.orderStatuses.length > 0 && this.filterParams.orderStatuses.includes('ORDER_CANCELED')) {
        this.filterParams.orderStatuses = this.filterParams.orderStatuses.concat(['IN_REFUNDING','ORDER_CLOSED','REFUNDED'])
      }
      this.filterPanelVisible = false
      this.onRefresh()
    },
    // 选择
    onCheckChanged(checked, { id }) {
      const ids = []
      this.$refs.list.list.forEach(item => {
        if (item.id === id) {
          item.checked = checked
        }
        if (item.checked) ids.push(item.id)
      })
      this.distributionIds = ids
    },
    // 取消
    cleanDistribution() {
      this.distributionIds = []
      this.$refs.list.list.forEach(item => {
        item.checked = false
      })
      this.isDistributionState = false
    },
    // 全选
    onAllChecked() {
      const ids = []
      this.$refs.list.list.forEach(item => {
        item.checked = true
        ids.push(item.id)
      })
      this.distributionIds = ids
    },
    // 分配
    onDistribution() {
      if (!this.distributionIds.length) return this.$toast(this.$t('请选择待分配的订单'))
      this.showDistribution = true
    },
    // 跳转至订单详情
    goDetail({ id }) {
      this.$router.push({
        path: '/order-detail',
        query: {
          id,
        },
      })
    },
    // 操作面板
    operation() {
      this.isDistributionState = true
    },
    // 确认分配
    async distributionFilter(data) {
      const { saIdList = [] } = data
      if (!saIdList.length) {
        this.$toast(this.$t('请选择分配人员！'))
        return false
      }
      const params = {
        orderIds: this.distributionIds,
        salesUserId: saIdList[0]?.id,
        salesUserName: saIdList[0]?.name,
      }
      loading.showLoading()
      const res = await orderServices.orderAssign(params)
      loading.hideLoading()
      this.$toast.success(this.$t('分配成功'))
      setTimeout(() => {
        this.onRefresh()
      }, 1000)
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const {
        queryType,
        searchStr
      } = this
      const params = {
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        queryType,
        searchStr,
        // orderCreatedTime: {
        //   start: this.filterParams.start,
        //   end: this.filterParams.end,
        // },
        lockTimeReq: {
          start: this.filterParams.lockTimeStart,
          end: this.filterParams.lockTimeEnd,
        },
        deliveryTime: {
          start: this.filterParams.deliveryTimeStart,
          end: this.filterParams.deliveryTimeEnd
        },
        contractTimeReq: {
          start: this.filterParams.contractTimeStart,
          end: this.filterParams.contractTimeEnd
        },
      }
      if (this.roleCode) params.roleCode = this.roleCode
      loading.showLoading()
      return orderServices.getOrderList(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach(item => item.checked = false)
        return res
      })
    },
  }
}
</script>
<style scoped lang="less">
.orderList {
  .totalCount {
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }

  .action-bar {
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    background: #FFFFFF;
    z-index: 999;

    .left {
      padding-left: 16px;

      span {
        display: inline-block;
        min-width: 28px;
        height: 22px;
        line-height: 22px;
        margin: 0 8px;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
      }
    }

    /deep/.van-button {
      color: #0D171A !important;
      width: 92px;
      font-weight: 500;
    }
  }
}
</style>
